import React, { useState, useEffect } from "react";
import {
  useLocation,
  useHistory,
  Link
} from "react-router-dom";
import sprite from '../media/icons.svg';
import './Footer.css';
function Footer() {
  const location = useLocation();
  const [dropdown, setDropdown] = useState(false);
  const [items, setItems] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [infoMenu, setInfoMenu] = useState([]);
  const [menu, setMenu] = useState([]);
  const [projectItems, setProjectItems] = useState([]);
  const [newsItems, setNewsItems] = useState([]);
  const [language] = useState(getLanguage(location));
  function getLanguage(location){
    if(location.pathname.indexOf('/ru') > -1) {
      return 'https://ukrservisproekt.com.ua/ru';
    } else if(location.pathname.indexOf('/en') > -1) {
      return 'https://ukrservisproekt.com.ua/en';
    } else {
      return 'https://ukrservisproekt.com.ua';
    }
  }
  function translateWords(obj, location){
    if(location.pathname.indexOf('/ru') > -1) {
      return obj.ru;
    } else if(location.pathname.indexOf('/en') > -1) {
      return obj.en;
    } else {
      return obj.ua;
    }
  }
  function dropdownHandler(){
    setDropdown(!dropdown);
  }
  const history = useHistory();
  function handlerComponents(event, path){
    event.preventDefault();
    document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loading-components"><div class="loading-components__blind"></div><div class="loading-components__blind"></div><div class="loading-components__blind"></div></div>');
    setTimeout(function() {
      history.push(path);
      document.querySelector('main').insertAdjacentHTML('afterbegin', '<div class="loader"><div id="loader"></div><div class="loader-section section-left"></div><div class="loader-section section-right"></div></div>');
    }, 1000);
  }
  useEffect(() => {
    fetch(`${language}/wp-json/acf/v3/options/acf-options-theme`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
        },
        (error) => {

        }
      )
  }, [language]);
  useEffect(() => {
      fetch(`${language}/wp-json/acf/v3/options/acf-contacts-theme`)
        .then(res => res.json())
        .then(
          (result) => {
            setContacts(result);
          },
          (error) => {

          }
        )
      }, [language]);
      useEffect(() => {
        fetch(`${language}/wp-json/wp/v3/main_menu`)
          .then(res => res.json())
          .then(
            (result) => {
              setMenu(result);
            },
            (error) => {

            }
          )
        }, [language]);
        useEffect(() => {
          fetch(`${language}/wp-json/wp/v3/info_menu`)
            .then(res => res.json())
            .then(
              (result) => {
                setInfoMenu(result);
              },
              (error) => {

              }
            )
          }, [language]);
          useEffect(() => {
            fetch(`${language}/wp-json/wp/v2/projects?per_page=3`)
              .then(res => res.json())
              .then(
                (result) => {
                  setProjectItems(result);
                },
                (error) => {

                }
              )
            }, [language]);
            useEffect(() => {
              fetch(`${language}/wp-json/wp/v2/notes?per_page=3`)
                .then(res => res.json())
                .then(
                  (result) => {
                    setNewsItems(result);
                  },
                  (error) => {

                  }
                )
  }, [language]);
  let urlProjects;
  let urlNotes;
  if(location.pathname.indexOf('/ru') > -1){
    urlProjects = '/ru/projects';
    urlNotes = '/ru/notes';
  } else if(location.pathname.indexOf('/en') > -1){
    urlProjects = '/en/projects';
    urlNotes = '/en/notes';
  } else {
    urlProjects = '/projects';
    urlNotes = '/notes';
  }
  let pathSlug;
  if(location.pathname.indexOf('/ru') > -1 || location.pathname.indexOf('/en') > -1){
    pathSlug = location.pathname.substr(location.pathname.indexOf('/')+3)
  } else {
    pathSlug = location.pathname;
  }
  let languageList;
  if(location.pathname.indexOf('/ru') > -1){
  languageList = <><li className="footer-language-list__item">
          <a className="footer-language-list__link" href={pathSlug}>Українська</a>
        </li>
        <li className="footer-language-list__item">
                <a className="footer-language-list__link" href={`/en${pathSlug}`}>English</a>
              </li></>;
  } else if(location.pathname.indexOf('/en') > -1){
    languageList = <><li className="footer-language-list__item">
            <a className="footer-language-list__link" href={pathSlug}>Українська</a>
          </li>
          <li className="footer-language-list__item">
                  <a className="footer-language-list__link" href={`/ru${pathSlug}`}>Русский</a>
                </li></>;
  } else {
    languageList = <><li className="footer-language-list__item">
            <a className="footer-language-list__link" href={`/ru${pathSlug}`}>Русский</a>
          </li>
          <li className="footer-language-list__item">
                  <a className="footer-language-list__link" href={`/en${pathSlug}`}>English</a>
                </li></>;
  }
  return (
<footer className="footer">
<div className="footer__top">
<div className="container">
{Object.values(items).map((item, index) => {
return <div className="footer__info" key={index}>
<Link to="/">
<img className="footer__logo" src={item.logo_light} alt="" />
</Link>
<p className="footer__description">{item.footer_description}</p>
</div>})}
<div className="footer__wgts">
<div className="footer-wgt footer-wgt__menu">
<h2 className="footer-wgt__headline">{translateWords({ua: 'Навігація', ru: 'Навигация', en: 'Navigation'}, location)}</h2>
<ul className="footer-list">
{Object.values(menu).map((menuItem, index) => {
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru${menuItem.url}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en${menuItem.url}`;
  } else {
    url = menuItem.url;
  }
  return <li className="footer-list__item" key={index}>
    <Link className="footer-list__link" to={url} onClick={event => {handlerComponents(event, url)}}>{menuItem.title}</Link>
  </li>
})}
</ul>
</div>
<div className="footer-wgt">
<h2 className="footer-wgt__headline">{translateWords({ua: 'Останні проєкти', ru: 'Последние проекты', en: 'Recent projects'}, location)}</h2>
<ul className="footer-list">
{Object.values(projectItems).map((projectItem, index) => {
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru/projects/${projectItem.slug}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en/projects/${projectItem.slug}`;
  } else {
    url = `/projects/${projectItem.slug}`;
  }
  return <li className="footer-list__item" key={index}>
    <Link className="footer-list__link" to={url} onClick={event => {handlerComponents(event, url)}}>{projectItem.title.rendered}</Link>
  </li>
})}
  <li className="footer-list__item">
    <Link className="footer-list__link footer-list__link_archive" to={urlProjects} onClick={event => {handlerComponents(event, urlProjects)}}>{translateWords({ua: 'Переглянути всі проєкти', ru: 'Просмотреть все проекты', en: 'View all projects'}, location)}
      <svg className="footer-list__link-arrow">
        <use href={sprite + "#arrow"}></use>
      </svg>
    </Link>
  </li>
</ul>
</div>
<div className="footer-wgt">
<h2 className="footer-wgt__headline">{translateWords({ua: 'Останні новини', ru: 'Последние новости', en: 'Latest news'}, location)}</h2>
<ul className="footer-list">
{Object.values(newsItems).map((newsItem, index) => {
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru/notes/${newsItem.slug}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en/notes/${newsItem.slug}`;
  } else {
    url = `/notes/${newsItem.slug}`;
  }
  return <li className="footer-list__item" key={index}>
    <Link className="footer-list__link" to={url} params={{slug: newsItem.slug}} onClick={event => {handlerComponents(event, url)}}>{newsItem.title.rendered}</Link>
  </li>
})}
  <li className="footer-list__item">
    <Link className="footer-list__link footer-list__link_archive" to={urlNotes} onClick={event => {handlerComponents(event, urlNotes)}}>{translateWords({ua: 'Переглянути всі новини', ru: 'Просмотреть все новости', en: 'View all news'}, location)}
      <svg className="footer-list__link-arrow">
        <use href={sprite + "#arrow"}></use>
      </svg>
    </Link>
  </li>
</ul>
</div>
{Object.values(contacts).map((contact, index) => {
  let objPhones = Object.assign({}, contact.phones);
  let objEmails = Object.assign({}, contact.emails);
return <div className="footer-wgt footer-wgt__contacts" key={index}>
<h2 className="footer-wgt__headline">{translateWords({ua: 'Наші контакти', ru: 'Наши контакты', en: 'Our contacts'}, location)}</h2>
<div className="footer-contacts-wrapper">
  <ul className="footer-list">
    {Object.values(objPhones).map((item, i) => {
    return <li className="footer-list__item" key={i}>
      <a className="footer-list__link" href={"tel:" + item.phone}>{item.phone}</a>
    </li>
    })}
    {Object.values(objEmails).map((item, i) => {
    return <li className="footer-list__item" key={i}>
      <a className="footer-list__link footer-list__link_theme_color" href={"mailto:" + item.emails}>{item.emails}</a>
    </li>})}
    <li className="footer-list__item">{contact.address}</li>
  </ul>
  <ul className="footer-social">
    <li className="footer-social__item">
      <a className="footer-social__link" href={contact.facebook_link} target="_blank" rel="noreferrer">
        <svg className="footer-social__icon">
          <use href={sprite + "#facebook"}></use>
        </svg>
      </a>
    </li>
    <li className="footer-social__item">
      <a className="footer-social__link" href={contact.youtube_link} target="_blank" rel="noreferrer">
        <svg className="footer-social__icon">
          <use href={sprite + "#youtube"}></use>
        </svg>
      </a>
    </li>
    <li className="footer-social__item">
      <a className="footer-social__link" href={contact.telegram_link} target="_blank" rel="noreferrer">
        <svg className="footer-social__icon">
          <use href={sprite + "#telegram"}></use>
        </svg>
      </a>
    </li>
  </ul>
</div>
</div>
})}
</div>
</div>
</div>
<div className="footer__bottom">
<div className="container footer__bottom-inner">
<div className="footer-bottom-language">
<div className={dropdown ? 'footer-language active' : 'footer-language'}>
  <div className="footer-language__content">
    <ul className="footer-language-list">
      {languageList}
    </ul>
  </div>
<button className="footer-language__btn" type="button" onClick={dropdownHandler}>{translateWords({ua: 'Українська', ru: 'русский', en: 'English'}, location)}
  <svg className="footer-language__icon">
    <use href={sprite + "#arrow-up"}></use>
  </svg>
</button>
</div>
</div>
<div className="footer-bottom-menu">
<ul className="footer-menu">
{Object.values(infoMenu).map((menuItem, index) => {
  let url;
  if(location.pathname.indexOf('/ru') > -1){
    url = `/ru${menuItem.url}`;
  } else if(location.pathname.indexOf('/en') > -1){
    url = `/en${menuItem.url}`;
  } else {
    url = menuItem.url;
  }
return <li className="footer-menu__item" key={index}>
  <Link className="footer-menu__link" onClick={event => {handlerComponents(event, url)}} to={url}>{menuItem.title}</Link>
</li>
})}
</ul>
</div>
<div className="footer-bottom-copyright">
{Object.values(items).map((item, index) => {
return <p className="footer-copyright" key={index}>{item.copy_info}</p>
})}
</div>
</div>
</div>
</footer>
);
}
export default Footer;
